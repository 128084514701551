import { Faq } from 'interfaces/components/Faq';
import styled from 'styled-components';
import { breakpoint, color, font } from 'styles/globals';
import { getBodyStyle, Theme, getColor } from 'styles/theme';
import Heading from 'styles/typography/Heading';

export const WrapperTitle = styled.div`

  .dropdown-ranking {
    margin-bottom: 24px;
    ul.open {
      transform: translate(0, 0px);
      left: 16px;
    }
  }

  @media(${breakpoint.mdMin}) {
    padding-left: 0;

    h1 {
      font-size: 48px;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
  
  @media(${breakpoint.lgMin}) {
    h1 {
      font-size: 60px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
`;

export const DescriptionSection = styled.section`
  background: ${color.lightBlue};
  padding-bottom: 60px;
  
  @media(${breakpoint.xlMin}) {
    background: none;
    float: left;
    width: 50%;
  }
`;

export const DescriptionWrapper = styled.div`
  background-color: ${color.lightBlue};
  max-width: 200%;
  width: 100%;

  @media(${breakpoint.mdMin}) {
    width: 100%;
  }
  @media(${breakpoint.xlMin}) {
    padding-bottom: 70px;
    margin-left: 5%;
    width: 205%;
  }
`;

export const DescriptionText = styled.div<{ theme: Theme }>`
  display: block;
  height: auto;
  font-family: ${font.graphikCond};
  font-size: 24px;
  font-weight: 300;
  line-height: 26.4px;
  margin-bottom: 16px;
  padding: 16px 16px 0;
  position: relative;
  transition: all 0.25s;
  width: 100%;
  @media(${breakpoint.mdMin}) {
    padding: 16px 24px 0;
  }
  @media (${breakpoint.xlMin}) {
    font-size: 32px;
    line-height: 35.2px;
    margin-bottom: 0;
    padding: 48px;
    width: 50%;
  }
  & > a {
    color: ${getColor('textLink')};
    &:hover {
      text-decoration: underline;
    }
    &:active {
      text-decoration: none;
      color: ${getColor('neutralBlack')};
    }
  }
  em {
    display: contents;
  }
`;

export const DescriptionVideo = styled.div`
  margin-top: 24px;
  @media(${breakpoint.mdMin}) {
    margin-left: 24px;
    margin-right: 24px;
  }
  @media(${breakpoint.xlMin}) {
    margin-left: 80px;
    margin-top: -70px;
  }
`;

export const CallsToAction = styled.div`
  margin-top: 16px;

  a {
    display: block;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    
    @media(${breakpoint.mdMin}) {
      display: inline-block;
    }
  }

  .link {
    line-height: 16px;
    text-align: center;
    padding: 16px;
    font-weight: 500;

    @media(${breakpoint.mdMin}) {
      padding: 16px 16px 16px 0px;
    }
  }

  .black-button {
    display: block;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 16px;

	  @media(${breakpoint.mdMin}) {
      margin: 0 16px 16px 0;
      display: inline-block;
    }
  }
`;

export const RankingListSection = styled.section`
  display: flex;
  justify-content: center;

  @media(${breakpoint.mdMin}) {
    padding: 0 24px;
  }

  @media(${breakpoint.xlMin}) {
    padding-top: 90px;
  }
`;

export const RankingListContent = styled.div<{ theme: Theme }>`
  align-items: center;
  background-color: ${getColor('neutralWhite')};
  border-left: 4px solid ${color.lightBlue};
  display: flex;
  flex-direction: column;
  margin-top: -30px;
  width: 90%;
  padding-top: 1%;
  
  .year-filter {
    padding: 0 24px;
    width: 100%;
  }

  .slick-slide {
    text-align: center;
  }

  .year {
    margin: 16px 0;

    @media(${breakpoint.lgMin}) {
      padding-right: 35%;
    }

    @media(${breakpoint.xlMin}) {
     margin-left: -20%;
    }
  }

  @media(${breakpoint.mdMin}) {
    width: 100%;
    padding-top: 2%;
  }
`;

export const Items = styled.ol`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;
  padding: 0px;
  padding-bottom: 16px;

  & li:first-child {
    border-top: none;
    padding-top: 0px;
  }
`;

export const ListTeaserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  padding-bottom: 16px;
  
  li {
    display: flex;
  }
`;

export const ListTeaserTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  @media(${breakpoint.mdMin}) {
    padding: 0 24px;
  }
`;

const StyledTitle = styled(Heading)<{ theme: Theme }>`
  color: ${getColor('neutralDarkGray')};
  margin: auto 0;
`;

export const ListTeaserTitle = styled(StyledTitle)`
  margin: 24px 0 24px;
`;

export const ListTeaserHighlight = StyledTitle;

export const ListTeaserCallToAction = styled.div`
  padding: 0 8px;
  font-family: ${font.graphikCond};
  
  .black-button {
    padding: 16px 32px;
    margin-left: 8%;

    @media(${breakpoint.mdMin}) {
      display: inline-block;
      margin-left: 4% ;
    }
  }
  @media(${breakpoint.mdMin}) {
    padding: 0 16px;
  }
`;

export const ContentModules = styled.div`
  margin-top: 36px;
 
  @media(${breakpoint.mdMin}) {
    margin-top: 24px;
  }
`;

export const FeatureContentModuleWrapper = styled.div`
  display: flex;
  padding: 0 24px;
  width: 100%;

  @media(${breakpoint.lgMin}) {
    padding-left:72px;
  }
  @media(${breakpoint.xlMin}) {
   padding-left: 86px;
  }
  @media(${breakpoint.xxlMin}) {
   padding-left: 100px;
  }
`;

export const GridContentModuleWrapper = styled.div`
  @media(${breakpoint.mdMin}) {
    margin: 0 24px;
  }
`;

export const FeatureContentModuleSidebar = styled.div`
  height: fit-content;
`;

export const FaqAndMethodologyWrapper = styled.div<{ $faq?: Faq[] }>`
  display: flex;
  align-items: center;
  overflow-wrap: break-word;
  flex-direction: column;

 @media(${breakpoint.lgMin}) {
    ${({ $faq }) => ($faq ? 'flex-direction: row;' : 'flex-direction: column;')}
    margin-left: 2%;
  }

  @media(${breakpoint.xlMin}) {
    ${({ $faq }) => ($faq ? 'margin-left: 0%;' : null)}
   }

  @media(${breakpoint.xxlMin}) {
    margin-left: 0%;
   }
`;

export const ContentWrapper = styled.div`
  width: 100%;

  @media(${breakpoint.lgMin}) {
    width: 70%;
    align-self: flex-start; 
  }

  @media(${breakpoint.xlMin}) {
    width: 100%;
  }
`;

export const AdWrapper1 = styled.div`
  #RightRailFlex0 > div {
    display:flex;
    justify-content: center;
  }

  margin-top: 60px;

  @media(${breakpoint.mdMin}) {
    padding-left: 30%;
  }
  @media(${breakpoint.lgMin}) {
    padding-left: 35%;
  }
  @media(${breakpoint.xlMin}) {
    padding-left: 40%;
  }
`;

export const AdWrapper2 = styled.div<{ $faq?: Faq[] }>`
  margin-top: 48px;

  @media(${breakpoint.lgMin}) {
    align-self: flex-start;
    margin-right: 24px;
    // if methodology, we want ad to render next to it hence the margin-top, if no methodology, ad should be centered
    ${({ $faq }) => ($faq ? 'margin-top: 8%;' : 'margin: auto;')}
  }

  @media(${breakpoint.xlMin}) {
    margin-left:5%;
 }

  @media(${breakpoint.xxlMin}) {
    ${({ $faq }) => ($faq ? 'margin-top: 5%;' : 'margin-top: 0;')}
    margin-right: 5%;
  }
`;

export const FranchiseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 20px;

  @media(${breakpoint.mdMin}) {
    padding: 0;
  }

  @media(${breakpoint.lgMin}) {
    gap: 68px;
  }
`;

// FAQ SECTION
export const FaqSection = styled.div<{ theme: Theme }>`
  min-height: 250px;

  .title {
    text-transform: none ;
  }

  & > :first-child {
    ${getBodyStyle('graphikCond', { default: 'xlarge' }, 'semiBold')}
    margin-bottom: 24px;
  }

  @media(${breakpoint.mdMin}) {
    padding: 0 48px;
    & > :first-child {
      font-size: 32px;
    }
  }

  @media(${breakpoint.xlMin}) {
    padding: 0 212px;
  }
`;

export const AccordionWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  gap: 24px;

  span:first-of-type {
    ${getBodyStyle('graphikCond', { default: 'large' }, 'semiBold')}
    line-height: 24px;
    padding-right: 8px;
  }
`;

export const SearchWrapper = styled.div`
  scroll-margin-top: calc(var(--ld-height, 0) + 72px);
  .franchise-content {
    max-width: calc(${breakpoint.xxlVal} - 96px);
    margin: 40px 0 0;
    width: calc(100vw - 40px);
    padding: 0;
    
    @media (${breakpoint.mdMin}) {
      width: calc(100vw - 64px);
    }
  
    @media (${breakpoint.xlMin}) {
      margin: 24px auto 0;
      width: calc(100vw - 96px);
    }
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const VideoWrapper = styled.div`
  width: 100%;

  @media(${breakpoint.mdMin}) {
    max-width: 625px;
  }
`;
