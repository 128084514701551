/* eslint-disable import/named */
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

// Interfaces
import { FranchiseListSearch, FranchisePage, Grid } from 'interfaces/content/Ranking';
import { MenuItemProps } from 'interfaces/navigation/Header';

// Constants
import { RANKING_V2_PAGE, FAQ_ACCORDION } from 'constants/testsIds/rankingV2Page';
import { UIFragments } from 'utils/log/constants/uiFragments';

// Components
import InContent from 'components/Ad/AdSlot/InContent';
import ErrorBoundary from 'components/ErrorBoundary';
// import StnVideo from 'components/StnVideo';
// import VodVideo from 'components/VodVideo';

// Styles
import * as S from './RankingCompanyFranchisePageStyles';

const FeaturedImage = dynamic(() => import('components/Ranking/FeatureImage'));
const RankingList = dynamic(() => import('components/Ranking/RankingList'));

const SectionTitle = dynamic(() => import('components/Globals/SectionTitle'), {
  ssr: false,
});
const Accordion = dynamic(() => import('components/Globals/Accordion'), {
  ssr: false,
});
const RankingAnalytics = dynamic(() => import('components/Ranking/RankingAnalytics'), {
  ssr: false,
});
const VisualizeF500 = dynamic(() => import('components/Ranking/VisualizeF500'), {
  ssr: false,
});
const MoreRankings = dynamic(() => import('components/Ranking/MoreRankings'), {
  ssr: false,
});
const StnVideo = dynamic(() => import('components/StnVideo'), {
  ssr: false,
});
const VodVideo = dynamic(() => import('components/VodVideo'), {
  ssr: false,
});
const RankingSearchPage = dynamic(() => import('pages/RankingSearch/RankingSearchPage'), {
  ssr: false,
});
const MethodologyContent = dynamic(() => import('components/Ranking/Methodology'), {
  ssr: false,
});
const HighlightedCompanies = dynamic(() => import('components/Ranking/HighlightsCompany'), {
  ssr: false,
});
const GridContentModuleV2 = dynamic(() => import('components/GridContentModuleV2'), {
  ssr: false,
});

interface RankingFranchiseProps {
  franchise: FranchisePage;
  franchiseSearch: FranchiseListSearch;
  subDomain?: string;
  subMenu?: MenuItemProps[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RankingCompanyFranchisePage = ({ franchise, subDomain, subMenu, franchiseSearch }: RankingFranchiseProps) => {
  const {
    contentModules,
    faq,
    lists,
    listTeaser,
    listType,
    title,
    featuredImage,
    description,
    callsToAction,
    highlightedCompanies,
    methodology,
    slug,
  } = franchise;
  const [expandedSections, setExpandedSections] = useState<boolean[]>([true]);
  const { stnVideoId } = franchise;
  const { vodVideoId } = franchise;
  const showVideo = stnVideoId || vodVideoId;

  const router = useRouter();
  const [initialSearch, setInitialSearch] = useState('');
  const [initialFilters, setInitialFilters] = useState<{ [key: string]: string | boolean }>({});
  const isVizualizeValid = () => {
    let isValid = true;
    Object.entries(contentModules.feature).forEach(([, value]) => {
      if (value === null) isValid = false;
    });
    return isValid;
  };
  const hasVizualize = contentModules.feature && Object.keys(contentModules.feature).length > 0 && isVizualizeValid();
  const hasCoverage = contentModules.grid.topics && contentModules.grid.topics.length > 0;
  const coverageSection = {
    title: contentModules.grid.title,
    topics: contentModules.grid?.topics?.map((topic) => ({
      ...topic,
      image: topic.image || '/icons/ImagePlaceholder.svg',
    })),
  } as Grid;

  useEffect(() => {
    const queryString = new URLSearchParams(router.asPath.split('?')[1] || '');

    setInitialSearch(queryString.get('Name') || '');
    queryString.delete('Name');

    const filters: { [key: string]: string | boolean } = {};
    queryString.forEach((value, key) => {
      if (value === 'true' || value === 'false') {
        filters[key] = value === 'true';
      } else {
        filters[key] = value;
      }
    });
    setInitialFilters(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [franchiseSearch]);

  const toggleFaqExpanded = (index: number) => {
    setExpandedSections(() => {
      const updatedSections = [false];
      updatedSections[index] = !updatedSections[index];
      return updatedSections;
    });
  };

  return (
    <S.FranchiseWrapper>
      <ErrorBoundary fragment={UIFragments.RANKING_FRANCHISE_PAGE}>
        <FeaturedImage
          image={featuredImage}
          description={description}
          title={title}
          callToAction={callsToAction}
          hasMethodology={methodology?.length !== undefined && methodology.length > 0}
        />
      </ErrorBoundary>

      <ErrorBoundary fragment={UIFragments.RANKING_FRANCHISE_PAGE}>
        <RankingList
          lists={lists}
          title={title}
          listTeaser={listTeaser}
          listType={listType}
          year={franchiseSearch.year}
        />
      </ErrorBoundary>

      <ErrorBoundary fragment={UIFragments.RANKING_FRANCHISE_PAGE}>
        {showVideo && (
          <S.VideoContainer>
            <S.VideoWrapper data-cy={RANKING_V2_PAGE.VIDEO}>
              {stnVideoId ? (
                <StnVideo
                  videoId={stnVideoId}
                  dataCy={RANKING_V2_PAGE.STN_VIDEO}
                />
              ) : (
                vodVideoId && (
                  <VodVideo
                    dataCy={RANKING_V2_PAGE.VIDEO}
                    videoPosterCy={RANKING_V2_PAGE.VIDEO_POSTER}
                    videoId={vodVideoId}
                  />
                )
              )}
            </S.VideoWrapper>
          </S.VideoContainer>
        )}
      </ErrorBoundary>

      <ErrorBoundary fragment={UIFragments.RANKING_FRANCHISE_PAGE}>
        {highlightedCompanies.length > 0 && <HighlightedCompanies highlights={highlightedCompanies} />}
      </ErrorBoundary>
      <InContent id={Math.random()} />

      <ErrorBoundary fragment={UIFragments.RANKING_FRANCHISE_PAGE}>
        <S.SearchWrapper id='search-section'>
          <SectionTitle
            tag='h2'
            text={`The Full ${franchise.title} List`}
            variant='border'
            titleDataCy={RANKING_V2_PAGE.FILTER_TITLE}
          />
          <RankingSearchPage
            franchiseList={franchiseSearch}
            initialFilters={initialFilters}
            years={franchiseSearch.years}
            initialYear={franchiseSearch.year}
            initialSearch={initialSearch}
            showTitle={false}
            initialRowCount={25}
          />
        </S.SearchWrapper>
      </ErrorBoundary>

      {contentModules.relatedRankings.length > 0 && <MoreRankings relatedRankings={contentModules.relatedRankings} />}

      <InContent id={Math.random()} />

      <ErrorBoundary fragment={UIFragments.RANKING_FRANCHISE_PAGE}>
        {hasVizualize && (
          <VisualizeF500
            feature={contentModules.feature}
            mainTitle={`Visualize the ${franchise.title}`}
          />
        )}
      </ErrorBoundary>

      <ErrorBoundary fragment={UIFragments.RANKING_FRANCHISE_PAGE}>
        {hasCoverage && (
          <GridContentModuleV2
            grid={coverageSection}
            dataCy={RANKING_V2_PAGE.COVERAGE_MODULE}
          />
        )}
      </ErrorBoundary>

      <ErrorBoundary fragment={UIFragments.RANKING_FRANCHISE_PAGE}>
        {methodology?.length && (
          <MethodologyContent
            methodology={methodology}
            mainTitle={`Methodology for ${title}`}
            idProp='methodology'
          />
        )}
      </ErrorBoundary>

      {(hasVizualize || hasCoverage || methodology?.length) && <InContent id={Math.random()} />}

      <ErrorBoundary fragment={UIFragments.RANKING_FRANCHISE_PAGE}>
        {(slug === 'fortune500' || slug === 'global500') && <RankingAnalytics />}
      </ErrorBoundary>

      <ErrorBoundary fragment={UIFragments.RANKING_FRANCHISE_PAGE}>
        {faq.length > 0 && (
          <S.FaqSection data-cy={FAQ_ACCORDION.WRAPPER}>
            <SectionTitle
              tag='h2'
              text='Frequently Asked Questions'
              variant='border'
              titleDataCy={RANKING_V2_PAGE.FAQ_TITLE}
            />
            <S.AccordionWrapper>
              {faq.map((item, index) => (
                <Accordion
                  index={index}
                  title={item.question}
                  key={`${item}`}
                  onClick={() => toggleFaqExpanded(index)}
                  isExpanded={expandedSections[index] || false}
                  className='ranking-franchise'
                  dataCy={FAQ_ACCORDION}
                >
                  {item.answer}
                </Accordion>
              ))}
            </S.AccordionWrapper>
          </S.FaqSection>
        )}
      </ErrorBoundary>
    </S.FranchiseWrapper>
  );
};

export default RankingCompanyFranchisePage;
