const COMPANY_PAGE_TEST_IDS = {
  ABOUT_CONTENT: 'company-page-about-content',
  ABOUT_CREDIT: 'company-page-about-credit',
  ABOUT_DESCRIPTION: 'company-page-about-description',
  ABOUT_FEATURED: 'company-page-about-featured',
  ABOUT_TAB: 'company-page-about-tabs',
  ACCOLADES_CONTAINER: 'company-page-accolades-wrapper',
  ACCOLADES_LABEL: 'company-page-accolades-label',
  ACCOLADES_LINK: 'company-page-accolades-link',
  ACCOLADES_RANK: 'company-page-accolades-rank',
  ACCOLADES_TITLE: 'company-page-accolades-title',
  COMPANY_INFORMATION: 'company-page-company-information',
  COMPANY_IN_FORTUNE_RANKINGS: 'company-in-fortune-rankings',
  COMPANY_TITLE: 'company-page-company-title',
  FORTUNE_RANKINGS_COMPANY_RANK: 'company-fortune-rankings-company-rank',
  FORTUNE_RANKINGS_FULL_LIST_BTN: 'company-fortune-rankings-full-list-btn',
  FORTUNE_RANKINGS_TITLE: 'company-fortune-rankings-title',
  LATEST_NEWS: 'company-latest-news',
  LATEST_NEWS_ARTICLE_DATE: 'company-latest-news-article-date',
  LATEST_NEWS_ARTICLE_LINK: 'company-latest-news-article-link',
  LATEST_NEWS_ARTICLE_TITLE: 'company-latest-news-article-title',
  LATEST_NEWS_TITLE: 'company-latest-news-title',
  LATEST_NEWS_WRAPPER: 'company-latest-news-wrapper',
  LIVE_SOCIAL_COMPANY_FEED: 'company-feed-live-social',
  MOST_POPULAR_CARD: 'company-page-most-popular-card-item-',
  MOST_POPULAR_DATE: 'company-page-most-popular-date',
  MOST_POPULAR_EXPLORE_MORE: 'company-page-most-popular-explore-more',
  MOST_POPULAR_TITLE: 'company-page-most-popular-title',
  STOCK_CHART: 'company-stock-chart',
  TRADING_VIEW_CONTAINER: 'company-page-trading-view-container',
  TRADING_VIEW_REDIRECT: 'company-page-trading-view-redirect',
  VIDEOS_CONTAINER: 'company-videos-container',
  VIDEOS_TITLE: 'company-videos-title',
  VIDEOS_WRAPPER: 'company-videos-wrapper',
  VIDEO_CATEGORY: 'company-video-category',
  VIDEO_CREDIT: 'company-video-credit',
  YEAR_BUTTON: 'company-fortune-year-buttons',
};

export type TestIds = {
  [key in keyof typeof COMPANY_PAGE_TEST_IDS]?: string;
};

const getCompanyPageTestId = (value: keyof TestIds) => COMPANY_PAGE_TEST_IDS[value];

export default getCompanyPageTestId;
