import setIterablePageData from 'services/Iterable/setIterablePageData';
import { setPianoConfig } from 'services/Piano';
import dynamic from 'next/dynamic';
import { FranchiseListSearch, FranchisePage } from 'interfaces/content/Ranking';
import { GetStaticPaths, GetStaticProps } from 'next';
import { getFranchiseBySlugAndYear } from 'api/Ranking/getFranchiseBySlugAndYear';
import { setHeadData } from 'utils/metaDataUtils/headData';
import setPageGtmConfig from 'services/Gtm/setPageGtmConfig';
import getFooter from 'api/Footer/getFooter';
import { HeaderNav, MenuItemProps } from 'interfaces/navigation/Header';
import setFranchisePageAdConfig from 'services/Ad/config/setFranchisePageAdConfig';
import { PageAdConfig } from 'interfaces/ads/Ad';
import { setPermutivePageConfig } from 'services/Permutive/setPermutivePageConfig';
import { PermutivePageConfig } from 'services/Permutive/entities';
import handleStaticPropsError from 'utils/handleStaticPropsError';
import getMenu from 'api/Header/getMenu';
import { PreviewData } from 'interfaces/PreviewData';
import { getFranchiseYearSearch } from 'api/Ranking/getFranchiseListItems';
import { safe, safeAsync } from 'utils/errorHandlingUtils';
import fallbackData from 'lib/fallbackData/commonData';

const SimpleRankingPage = dynamic(() => import('pages/SimpleRanking/SimpleRankingPage'));
const RankingCompanyFranchisePage = dynamic(() => import('pages/RankingCompanyFranchise/RankingCompanyFranchisePage'));

interface FranchisePageProps {
  franchise: FranchisePage;
  franchiseSearch: FranchiseListSearch;
  subMenu?: MenuItemProps[];
}

const RankingFranchiseNextPage = ({ franchise, subMenu, franchiseSearch }: FranchisePageProps) =>
  (franchise.listType === 'grid' ? (
    <SimpleRankingPage franchiseList={franchise} />
  ) : (
    <RankingCompanyFranchisePage
      franchise={franchise}
      franchiseSearch={franchiseSearch}
      subMenu={subMenu}
    />
  ));

export const getStaticProps: GetStaticProps = async (context) => {
  try {
    const platformCD: string = 'own';
    const { franchiseSlug } = context.params as { franchiseSlug: string };
    const { previewData } = context as { previewData: PreviewData };

    const franchise = await getFranchiseBySlugAndYear(franchiseSlug, platformCD, undefined, previewData);
    const franchiseSearch = await safeAsync(
      () => getFranchiseYearSearch(franchiseSlug, undefined, previewData),
      {} as FranchiseListSearch,
    );
    const footer = await safeAsync(() => getFooter(), fallbackData.footer);
    const menu: HeaderNav = await safeAsync(
      () => getMenu('LEFT_MENU_COM', 'RIGHT_MENU_COM', 'TOPIC_NAVIGATION_COM', 'own', null),
      fallbackData.headerNav,
    );
    const subMenu = menu?.topicNavigation.find((menuItem) => menuItem.label === franchise.title)?.subMenu || null;
    const iterablePageViewData = safe(() => setIterablePageData(franchise), fallbackData.iterablePageViewData);
    const pageAdConfig: PageAdConfig = safe(() => setFranchisePageAdConfig(franchise), fallbackData.pageAdConfig);
    const permutivePageConfig: PermutivePageConfig = safe(
      () => setPermutivePageConfig(franchise),
      fallbackData.permutivePageConfig,
    );
    const pianoPageConfig = safe(() => setPianoConfig(franchise, true), fallbackData.pianoPageConfig);
    const dataLayer = safe(() => setPageGtmConfig(franchise), fallbackData.dataLayer);
    const headData = safe(() => setHeadData(franchise), fallbackData.headData);

    pageAdConfig.ctype = 'franchise';

    return {
      props: {
        dataLayer,
        footer,
        franchise,
        franchiseSearch,
        headData,
        headerNav: menu,
        iterablePageViewData,
        pageAdConfig,
        permutivePageConfig,
        pianoPageConfig,
        subMenu,
      },
      revalidate: 60, // In seconds
    };
  } catch (error) {
    return handleStaticPropsError(error as Error);
  }
};

export const getStaticPaths: GetStaticPaths = () => {
  if (process.env.TEST) {
    return {
      fallback: 'blocking',
      paths: [
        {
          params: {
            franchiseSlug: '40-under-40',
          },
        },
        {
          params: {
            franchiseSlug: 'fortune500',
          },
        },
      ],
    };
  }

  return {
    fallback: 'blocking',
    paths: [
      {
        params: {
          franchiseSlug: 'fortune500',
        },
      },
      {
        params: {
          franchiseSlug: 'global500',
        },
      },
    ],
  };
};

export default RankingFranchiseNextPage;
